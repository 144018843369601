(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("window"), require("solid-auth-client"));
	else if(typeof define === 'function' && define.amd)
		define(["window", ], factory);
	else if(typeof exports === 'object')
		exports["$rdf"] = factory(require("window"), require("solid-auth-client"));
	else
		root["$rdf"] = factory(root["window"], root["solid"]["auth"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__41__, __WEBPACK_EXTERNAL_MODULE__80__) {
return 