var ClassOrder = {
  'Literal': 1,
  'Collection': 3,
  'Graph': 4,
  'NamedNode': 5,
  'BlankNode': 6,
  'Variable': 7
}

module.exports = ClassOrder
