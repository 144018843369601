/**
 * A Dummy log
 * @module log
 */
module.exports = {
  debug: function debug (x) {
    return
  },
  warn: function warn (x) {
    return
  },
  info: function info (x) {
    return
  },
  error: function error (x) {
    return
  },
  success: function success (x) {
    return
  },
  msg: function msg (x) {
    return
  }
}
